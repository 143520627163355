@import "styles/colors";
@import "styles/breakpoints";

.lineHeader {
  display: flex;
  align-content: stretch;

  .left {
    position: relative;
    flex-grow: 1;
  }
  .right {
    position: relative;
    flex-grow: 1;
  }
  .line {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    height: 0;
    border-top: 1px solid #dcdcdc;
  }
  .content {
    margin: 0 10px;
  }
}
