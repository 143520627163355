@import "styles/colors";
@import "styles/breakpoints";

.container {
  user-select: none;
  padding: 23px 30px;

  @media only screen and (min-width: $TABLET) {
    padding-top: 47px;
    padding-bottom: 37px;
    // with padding from .container fields are 540px
    max-width: 600px;
    margin: 0 auto;
    @media only screen and (min-width: $DESKTOP) {
      max-width: 1100px;
      padding-top: 75px;
      padding-bottom: 95px;
    }
  }

  a {
    color: $BRAND;
    font-weight: bold;
  }

  .passwordReset {
    font-size: 1rem;
    float: right;
  }

  .content {
    h2 {
      font-size: 3rem;
      text-align: center;
      @media only screen and (min-width: $TABLET) {
        font-size: 3.4rem;
      }
      @media only screen and (min-width: $DESKTOP) {
        font-size: 5.4rem;
      }
    }

    // Desktop grid
    .desktopGrid {
      @media only screen and (min-width: $DESKTOP) {
        margin-top: 55px;
        display: grid;
        grid-template-columns: 1fr 100px 1fr;
      }
    }

    .authError {
      color: $ERROR_RED;
      font-size: 1.2rem;
      @media only screen and (min-width: $TABLET) {
        margin-top: 38px;
      }
      @media only screen and (min-width: $DESKTOP) {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }

    .fields {
      display: grid;
      row-gap: 10px;
      column-gap: 60px;
      margin-top: 27px;

      @media only screen and (min-width: $TABLET) {
        grid-template-columns: 1fr;
      }
      @media only screen and (min-width: $DESKTOP) {
        margin: 0;
      }
    }
  }

  .socialSeparator {
    margin-top: 30px;
    margin-bottom: 30px;
    @media only screen and (min-width: $DESKTOP) {
      display: none;
    }
  }

  .verticalSeparator {
    display: none;
    text-align: center;
    @media only screen and (min-width: $DESKTOP) {
      display: flex;
      flex-direction: column;
    }
    .line {
      height: 100px;
      align-self: center;
      border-left: 1px solid #dcdcdc;
    }
  }

  .buttonContainer {
    .button {
      width: 100%;
      margin-top: 55px;
      @media only screen and (min-width: $TABLET) {
        margin-top: 86px;
      }
      @media only screen and (min-width: $TABLET) {
        margin-top: 46px;
      }
    }
  }
}
